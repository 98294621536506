/* Define the Recia Variable Italic font */
@font-face {
    font-family: 'Recia';
    src: url('../fonts/Recia-VariableItalic.ttf') format('truetype');
}

/* Define the Satoshi Variable font */
@font-face {
    font-family: 'Satoshi';
    src: url('../fonts/Satoshi-Variable.ttf') format('truetype');
}

/* Apply these fonts to the body and specific elements */
body {
    font-family: 'Satoshi', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Recia', serif;
}